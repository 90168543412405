import React from "react";

import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

type AnalyticBoxProps = {
  icon: React.ReactElement;
  count: number;
  label: string;
};

const AnalyticsBox: React.FC<AnalyticBoxProps> = ({ icon, count, label }) => {
  return (
    <Grid item xs={3} sx={{ margin: 1 }}>
      <Paper
        sx={{
          p: 2,
          pl: 4,
          pr: 4,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#E7E7F1",
          justifyContent: "space-between",
          boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)", // Decreased box shadow blur
        }}
      >
        {icon}
        <Box textAlign="right">
          <Typography component="p" variant="h4">
            {count}
          </Typography>
          <Typography component="p" variant="body1">
            {label}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

export default AnalyticsBox;
