import axios from "../axios";
import AuthService from "./auth/AuthService";

type DeleteFleetProps = {
  fleetId: number;
};

const deleteFleet = ({ fleetId }: DeleteFleetProps): Promise<void> => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/fleet/${fleetId}`, {
        headers: {
          Authorization: `Bearer ${AuthService.getCurrentUser().access_token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          resolve();
        } else {
          reject("Error deleting fleet");
        }
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data);
        } else {
          reject("Unknown error occurred.");
        }
      });
  });
};

export default deleteFleet;
