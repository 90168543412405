import React from "react";

import { Button, ButtonProps } from "@mui/material";

type CustomButtonProps = {
  label: string;
  handleClick: () => void;
  buttonColor?: string;
} & ButtonProps;

const ButtonWithText: React.FC<CustomButtonProps> = ({
  label,
  handleClick,
  buttonColor = "#282583",
  ...rest
}) => {
  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      size="large"
      sx={{
        backgroundColor: buttonColor,
        color: "#fff",
        "&:hover": { backgroundColor: "#fff", color: buttonColor },
      }}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default ButtonWithText;
