import { Link } from "react-router-dom";

import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

type NavbarItemProps = {
  to?: string;
  onClick?: () => void;
  icon: React.ReactElement;
  text: string;
};

export const NavbarItem: React.FC<NavbarItemProps> = ({
  to,
  onClick,
  icon,
  text,
}) => {
  return (
    <ListItemButton
      component={to ? Link : "div"}
      to={to}
      onClick={onClick}
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
          color: "#282583",
          "& svg": {
            color: "#282583",
          },
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};
