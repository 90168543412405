import axios from "../axios";
import { MagicLinkFleetData } from "../pages/Home";
import AuthService from "./auth/AuthService";

type GenerateMagicLinkProps = {
  fleetData: MagicLinkFleetData;
  expValue: number;
};

const generateMagicLink = ({
  fleetData,
  expValue,
}: GenerateMagicLinkProps): Promise<string> => {
  const redirectURISuccess = fleetData.isFleetCustomRedirect
    ? fleetData.fleetRedirectURISuccess
    : "";
  const redirectURIError = fleetData.isFleetCustomRedirect
    ? fleetData.fleetRedirectURIError
    : "";

  return new Promise((resolve, reject) => {
    axios
      .post(
        "/service/link_token",
        {
          fleet_code: fleetData.fleetCode,
          redirect_uri_success: redirectURISuccess,
          redirect_uri_error: redirectURIError,
          duration_seconds: expValue,
        },
        {
          headers: {
            Authorization: `Bearer ${
              AuthService.getCurrentUser().access_token
            }`,
          },
        },
      )
      .then((res) => {
        if (res.status === 201) {
          resolve(res.data.url);
        } else {
          reject("Error generating magic link");
        }
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data);
        } else {
          reject("Unknown error occurred.");
        }
      });
  });
};

export default generateMagicLink;
