import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { User } from "../types/User";

type UserListProps = {
  users: User[];
};

export default function UserList({ users }: UserListProps) {
  if (users) {
    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: User) => (
              <TableRow hover key={user.ID}>
                <TableCell>{user.ID}</TableCell>
                <TableCell>{user.Firstname}</TableCell>
                <TableCell>{user.Lastname}</TableCell>
                <TableCell>{user.Email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  } else {
    return <Typography>No users</Typography>;
  }
}
