import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import ButtonWithText from "../ButtonWithText";
import ExpirationSlider from "./ExpirationSlider";
import { MagicLinkFleetData } from "../../pages/Home";

type FleetLinkDialogProps = {
  magicLinkFleetData: MagicLinkFleetData;
  setMagicLinkFleetData: React.Dispatch<
    React.SetStateAction<MagicLinkFleetData>
  >;
  expValue: number;
  setExpValue: React.Dispatch<React.SetStateAction<number>>;
  generateMagicLink: (redirect: boolean) => void;
  serviceID: number;
};

export default function FleetLinkDialog({
  magicLinkFleetData,
  setMagicLinkFleetData,
  expValue,
  setExpValue,
  generateMagicLink,
  serviceID,
}: FleetLinkDialogProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <ButtonWithText label="Link Fleets" handleClick={handleClickOpen} />
      <Dialog open={openDialog} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Grid container justifyContent="flex-end">
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box px={1} py={1}>
            <Grid
              container
              direction={"column"}
              alignItems={"center"}
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#E50C45" }}
                >
                  Magically Link Fleet
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="fleet-code"
                  label="Fleet Code"
                  variant="outlined"
                  value={magicLinkFleetData.fleetCode}
                  onChange={(e) =>
                    setMagicLinkFleetData((prevState) => ({
                      ...prevState,
                      fleetCode: e.target.value,
                    }))
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </Grid>
              {process.env.REACT_APP_NODE_ENV !== "prod" ||
              serviceID === 320 ? (
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={magicLinkFleetData.isFleetCustomRedirect}
                          onChange={() =>
                            setMagicLinkFleetData((prevState) => ({
                              ...prevState,
                              isFleetCustomRedirect:
                                !prevState.isFleetCustomRedirect,
                            }))
                          }
                        />
                      }
                      label="Use custom redirects"
                    />
                  </FormGroup>
                </Grid>
              ) : null}
              {magicLinkFleetData.isFleetCustomRedirect ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      id="fleet-redirect-uri-success"
                      label="Success Redirect URI"
                      variant="outlined"
                      value={magicLinkFleetData.fleetRedirectURISuccess}
                      onChange={(e) =>
                        setMagicLinkFleetData((prevState) => ({
                          ...prevState,
                          fleetRedirectURISuccess: e.target.value,
                        }))
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="fleet-redirect-uri-error"
                      label="Error Redirect URI"
                      variant="outlined"
                      value={magicLinkFleetData.fleetRedirectURIError}
                      onChange={(e) =>
                        setMagicLinkFleetData((prevState) => ({
                          ...prevState,
                          fleetRedirectURIError: e.target.value,
                        }))
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <ExpirationSlider
                  expValue={expValue}
                  setExpValue={setExpValue}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction={"column"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item>
              <Button
                onClick={() => generateMagicLink(false)}
                variant={"outlined"}
                size="large"
              >
                Copy Magic Link
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => generateMagicLink(true)}
                variant={"outlined"}
                size="large"
              >
                Magically Link
              </Button>
            </Grid>
            <Grid item style={{ height: "50px" }}></Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
