import AuthService from "./AuthService";

export default function getAuthHeader() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  if (!user || !user.access_token) {
    AuthService.logOut(); // User or access token doesn't exist, log out the user
    return { Authorization: "Bearer " };
  }

  return { Authorization: "Bearer " + user.access_token };
}
