import axios, { AxiosError, AxiosRequestConfig } from "axios";

export interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  _retry?: boolean;
  _bypassInterceptor?: boolean;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalConfig = error.config as AxiosRequestConfigExtended;

    // checks if bypassInterceptor is true.
    if (originalConfig._bypassInterceptor) {
      return Promise.reject(error);
    }

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;

      const AuthService = require("./helpers/auth/AuthService").default; // dynamically import AuthService to avoid circular dependency
      try {
        const newConfig = await AuthService.refreshToken(); // refresh token

        if (originalConfig.headers) {
          originalConfig.headers.Authorization = `Bearer ${newConfig}`; // Update auth header with new token
        } else {
          originalConfig.headers = { Authorization: `Bearer ${newConfig}` };
        }

        return instance(originalConfig);
      } catch (err) {
        AuthService.logOut(); // if refresh fails then log user out
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
