/*
Helper function for logout when token expires 5/23/2023
*/
import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import AuthService from "./AuthService";
import { withRouter } from "./WithRouter";

interface Props {
  logOut: () => void;
  children?: ReactNode;
}

const AuthVerify: React.FC<Props> = ({ logOut, children }) => {
  const location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "null");

    if (user !== null && user.expires_in && user.token_expiration) {
      const expiryTimeInMilliseconds = user.expires_in * 1000;
      const currentTime = Date.now();
      const timeDiff =
        user.token_expiration + expiryTimeInMilliseconds - currentTime;
      // refreshes 10 seconds before expires
      if (timeDiff <= 10000) {
        AuthService.refreshToken().catch(() => {
          logOut(); // refresh fails, logout
        });
      }
    }
  }, [location, logOut]);

  return <>{children}</>;
};

export default withRouter(AuthVerify);
