import { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";

import axios from "../axios";
import AnalyticsBar from "../components/charts/AnalyticsBar";
import getAuthHeader from "../helpers/auth/AuthHeader";
import AuthService from "../helpers/auth/AuthService";
import HomeLayout from "../layouts/HomeLayout";
import { ChartData } from "../types/ChartData";
import { Maybe } from "../types/Utility";

type ServiceActivityData = {
  label: string;
  data: Array<number>;
};

type ServiceActivityResponse = {
  labels: Array<string>;
  datasets: Array<ServiceActivityData>;
  last_updated: Date;
};

type APIMetricsData = {
  label: string;
  data: Array<number>;
};

type APIMetricsResponse = {
  labels: Array<string>;
  datasets: Array<APIMetricsData>;
  last_updated: Date;
};

export default function Analytics() {
  const auth = AuthService.getCurrentUser();
  const [serviceActivityData, setServiceActivityData] =
    useState<Maybe<ServiceActivityResponse>>(null);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());
  const [apiMetricsData, setAPIMetricsData] =
    useState<Maybe<APIMetricsResponse>>(null);

  // Chart data function and variables
  function getChartData(
    serviceActivityData: Maybe<ServiceActivityResponse>,
    label: string,
  ): Maybe<ChartData> {
    return serviceActivityData
      ? {
          labels: serviceActivityData.labels,
          datasets: serviceActivityData.datasets.filter(
            (dataset) => dataset.label === label,
          ),
        }
      : null;
  }

  function getAPIMetricsData(
    apiMetricsData: Maybe<APIMetricsResponse>,
    label: string,
  ): Maybe<ChartData> {
    return apiMetricsData
      ? {
          labels: apiMetricsData.labels,
          datasets: apiMetricsData.datasets.filter(
            (dataset) => dataset.label === label,
          ),
        }
      : null;
  }

  const fleetData = getChartData(serviceActivityData, "ActiveFleets");
  const vehicleData = getChartData(serviceActivityData, "ActiveVehicles");
  const driverData = getChartData(serviceActivityData, "ActiveDrivers");
  const tspData = getChartData(serviceActivityData, "ActiveTSPs");
  const assetData = getChartData(serviceActivityData, "ActiveAssets");
  const apiMetrics = getAPIMetricsData(apiMetricsData, "Total API Requests");

  useEffect(() => {
    // get service activity data
    axios
      .get<ServiceActivityResponse>(`/service/activity/${auth?.service_id}`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        setServiceActivityData(response.data);
        setLastUpdated(response.data.last_updated);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [auth?.access_token, auth?.service_id]);

  useEffect(() => {
    // get API metrics data
    axios
      .get<APIMetricsResponse>(`/service/api_metrics/`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        setAPIMetricsData(response.data);
        setLastUpdated(response.data.last_updated);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [auth?.access_token]);

  return (
    <HomeLayout>
      <Grid item xs={12}>
        <Typography component="h4" variant="h4">
          Analytics
        </Typography>
      </Grid>
      <Grid container direction={"row"} spacing={2} marginTop={1}>
        <Grid item xs={4}>
          <Box p={0.5}>
            {fleetData && (
              <AnalyticsBar
                data={fleetData}
                color="#3C38B4"
                label="Active Fleets Over Time"
                lastUpdated={lastUpdated}
                startAtZero
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box p={0.5}>
            {vehicleData && (
              <AnalyticsBar
                data={vehicleData}
                color="#FE9659"
                label="Active Vehicles Over Time"
                lastUpdated={lastUpdated}
                startAtZero
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box p={0.5}>
            {driverData && (
              <AnalyticsBar
                data={driverData}
                color="#D90038"
                label="Active Drivers Over Time"
                lastUpdated={lastUpdated}
                startAtZero
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box p={0.5}>
            {tspData && (
              <AnalyticsBar // Replaced component here
                data={tspData}
                color="#CB0035"
                label="Active TSPs Over Time"
                lastUpdated={lastUpdated}
                startAtZero
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box p={0.5}>
            {assetData && (
              <AnalyticsBar
                data={assetData}
                color="#282583"
                label="Active Assets Over Time"
                lastUpdated={lastUpdated}
                startAtZero
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box p={0.5}>
            {apiMetrics && (
              <AnalyticsBar
                data={apiMetrics}
                color="#123456"
                label="API Requests Over Time"
                lastUpdated={lastUpdated}
                startAtZero
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </HomeLayout>
  );
}
