import { Grid } from "@mui/material";

import Image from "../images/loginImage.png";

type LoginLayoutProps = {
  children: React.ReactNode;
};

export default function LoginLayout({ children }: LoginLayoutProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        minWidth: "100%",
        minHeight: "100vh",
        background: "#fafafa",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          background: "#fff",
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          minHeight: "100vh",
          backgroundImage: `url(${Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
    </Grid>
  );
}
