import axios from "../axios";
import getAuthHeader from "./auth/AuthHeader";

type EditFleetProps = {
  fleetId: number;
  fleetName: string;
  fleetCode: string;
};

const editFleet = ({
  fleetId,
  fleetName,
  fleetCode,
}: EditFleetProps): Promise<void> => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        `/fleet/${fleetId}`,
        {
          name: fleetName,
          fleet_code: fleetCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeader(),
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          resolve();
        } else {
          reject("Error deleting fleet");
        }
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data);
        } else {
          reject("Unknown error occurred.");
        }
      });
  });
};

export default editFleet;
