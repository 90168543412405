import { useEffect } from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { useNavigate } from "react-router-dom";

import { Vehicle } from "../../types/Vehicle";

type MapViewProps = {
  vehicles: Vehicle[];
};

type BoundsProps = {
  coords: [number, number][];
};

export default function FleetMapView({ vehicles }: MapViewProps) {
  const navigate = useNavigate();
  const vehicle_locations = vehicles.map((veh) => [
    veh.latitude,
    veh.longitude,
  ]);

  function Bounds({ coords }: BoundsProps) {
    const map = useMap();
    useEffect(() => {
      if (!map) return;

      map.fitBounds(coords);
    }, [map, coords]);
    return null;
  }

  return (
    <MapContainer center={[42.36, -71.059]} zoom={12} scrollWheelZoom={false}>
      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {vehicles.map((veh) => (
        <Marker
          key={veh.vehicle_id}
          position={[veh.latitude, veh.longitude]}
          eventHandlers={{
            click() {
              navigate(`/vehicleMap/${veh.vehicle_id}`);
            },
          }}
          //   icon={icon}
        />
      ))}
      {vehicle_locations.length > 0 ? (
        <Bounds coords={vehicle_locations as [number, number][]} />
      ) : null}
    </MapContainer>
  );
}
