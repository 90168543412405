/* Helper function for logout when token expires 5/23/2023 */
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface RouterProps {
  location: any;
  navigate: any;
  params: any;
}

export const withRouter = <P extends object>(
  Component: React.ComponentType<P & { router: RouterProps }>,
): React.ComponentType<P> => {
  const ComponentWithRouterProp: React.FC<P> = (props: P) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  };

  return ComponentWithRouterProp;
};
