import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Alert, Button, Grid, TextField, Typography } from "@mui/material";

import axios from "../axios";
import Logo from "../images/logo/axle logo.png";

export default function SignUp() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    company: "",
  });
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    axios
      .post(`/user/register?token=${params.get("token")}`, formData)
      .then((res) => {
        if (res.status === 201) {
          navigate("/login");
        } else {
          setShowError(true);
          setErrorText("Error occurred signing up");
        }
      })
      .catch((err) => {
        setShowError(true);
        if (err.response) {
          setErrorText(err.response.data);
        } else {
          setErrorText("Unknown error occurred.");
        }
      });
  };

  return (
    <Grid
      container
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh", background: "#fafafa" }}
    >
      <img
        src={Logo}
        alt="Axle Logo"
        style={{ width: "180px", height: "auto", marginBottom: "1em" }}
      />
      <Typography variant="h5" align="center" sx={{ mb: 2, mt: 2 }}>
        Sign Up for an Axle Developer Account
      </Typography>
      {showError ? <Alert severity={"error"}>{errorText}</Alert> : null}
      <TextField
        label="Email"
        type="email"
        sx={{ mb: 1, width: "60%", mt: 3 }}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
      />
      <TextField
        label="Password"
        type="password"
        sx={{ mb: 1, width: "60%", mt: 2 }}
        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
      />
      <TextField
        label="First Name"
        type="text"
        sx={{ mb: 1, width: "60%", mt: 2 }}
        onChange={(e) =>
          setFormData({ ...formData, firstname: e.target.value })
        }
      />
      <TextField
        label="Last Name"
        type="text"
        sx={{ mb: 1, width: "60%", mt: 2 }}
        onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
      />
      <TextField
        label="Company Name"
        type="text"
        sx={{ mb: 3, width: "60%", mt: 2 }}
        onChange={(e) => setFormData({ ...formData, company: e.target.value })}
      />
      <Button
        variant="outlined"
        type="submit"
        size="large"
        sx={{
          backgroundColor: "#282583",
          color: "#fff",
          "&:hover": { backgroundColor: "#fff", color: "#282583" },
          mb: 3,
        }}
      >
        Sign Up
      </Button>
    </Grid>
  );
}
