export type Fleet = {
  id: string;
  ID: number;
  TSPID: number;
  ServiceName: string;
  TSPName: string;
  FleetCode: string;
  FleetName: string;
  NumberOfDrivers: number;
  NumberOfVehicles: number;
  TotalVehicleCount: number;
  TotalDriverCount: number;
  StaleCreds: boolean;
  VerizonRevealStaleCreds: boolean;
};

// Initialize an empty Fleet object
export const initFleet: Fleet = {
  id: "",
  ID: 0,
  TSPID: 0,
  ServiceName: "",
  TSPName: "",
  FleetCode: "",
  FleetName: "",
  NumberOfDrivers: 0,
  NumberOfVehicles: 0,
  TotalVehicleCount: 0,
  TotalDriverCount: 0,
  StaleCreds: false,
  VerizonRevealStaleCreds: false,
};
