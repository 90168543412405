import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ArticleIcon from "@mui/icons-material/Article";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";

import axios from "../axios";
import HomeFooter from "../components/HomeFooter";
import { NavbarItem } from "../components/NavbarItem";
import getAuthHeader from "../helpers/auth/AuthHeader";
import AuthService from "../helpers/auth/AuthService";
import Logo from "../images/logo/axle logo.png";

type HomeLayoutProps = {
  children: React.ReactNode;
};

export default function HomeLayout({ children }: HomeLayoutProps) {
  const drawerWidth = 240;
  const [errMessage, setErrMessage] = useState("");
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  const handleSignOut = () => {
    AuthService.logOut();
    navigate("/login");
  };

  const handleErrClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrMessage("");
  };

  const hasBillingSetup = () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user && user.stripe_setup;
  };

  // stripe billing as a function instead of in route
  const handleBillingClick = () => {
    const authHeader = getAuthHeader();
    const access_token =
      authHeader && authHeader.Authorization
        ? authHeader.Authorization.replace("Bearer ", "")
        : ""; //check for null value
    axios
      .get("/service/stripebillingportal", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((err) => {
        setErrMessage(err.response.data);
      });
  };

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <AppBar
          position="relative"
          sx={{ background: "white", boxShadow: "none" }}
        >
          <Toolbar
            sx={{
              display: { xs: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Box
              component="img"
              sx={{
                height: "7vh",
              }}
              alt="Axle"
              src={Logo}
            />
            <Box sx={{ mt: 2, paddingLeft: 2, paddingRight: 8 }}>
              <Typography variant="subtitle2">
                <Box component="span" color="gray">
                  Email:
                </Box>
                <Box component="span" color="black">
                  {" "}
                  {currentUser && currentUser.email}
                </Box>
              </Typography>
              <Typography variant="subtitle2">
                <Box component="span" color="gray">
                  Service:
                </Box>
                <Box component="span" color="black">
                  {" "}
                  {currentUser && currentUser.service_name}
                </Box>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <List component="nav">
          <hr style={{ borderTop: "1px solid lightgray" }} />
          <NavbarItem to="/" icon={<DashboardIcon />} text="Dashboard" />
          <NavbarItem
            to="/analytics"
            icon={<AutoGraphIcon />}
            text="Analytics"
          />
          <NavbarItem
            icon={<ArticleIcon />}
            text="Documentation"
            onClick={() => {
              window.open(
                "https://docs.axleapi.com/reference/post_token",
                "_blank",
                "noopener,noreferrer",
              );
            }}
          />
          <NavbarItem to="/settings" icon={<SettingsIcon />} text="Settings" />
          {hasBillingSetup() && (
            <NavbarItem
              onClick={handleBillingClick}
              icon={<PaymentIcon />}
              text="Billing"
            />
          )}
        </List>
        <ListItemButton
          onClick={handleSignOut}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              color: "#282583",
              "& svg": { color: "#282583" },
            },
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            borderColor: "lightgray",
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItemButton>
      </Drawer>
      <Snackbar
        open={errMessage !== ""}
        autoHideDuration={6000}
        message={errMessage}
        onClose={handleErrClose}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          p: 6,
          pt: 8,
          pb: 0,
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#fafafa",
        }}
      >
        {children}
        <HomeFooter />
      </Box>
    </>
  );
}
