import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { Alert, Button, Grid, TextField, Typography } from "@mui/material";

import AuthService from "../helpers/auth/AuthService";
import Logo from "../images/logo/axle logo.png";

export default function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    AuthService.logIn(formData)
      .then((_) => {
        navigate("/");
      })
      .catch((error) => {
        setShowError(true);
        if (error.response) {
          setErrorText(error.response.data);
        } else {
          setErrorText("Unknown error occurred.");
        }
      });
  };

  return (
    <Grid
      container
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh", background: "#fafafa" }}
    >
      <img
        src={Logo}
        alt="Axle Logo"
        style={{ width: "180px", height: "auto", marginBottom: "1em" }}
      />
      <Typography variant="h5" align="center" sx={{ mb: 2, mt: 2 }}>
        Log into your Axle Developer Account
      </Typography>
      {showError ? <Alert severity={"error"}>{errorText}</Alert> : null}
      <TextField
        label="Username"
        type={"email"}
        sx={{ mb: 1, width: "60%", mt: 3 }}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
      />
      <TextField
        label="Password"
        type={"password"}
        sx={{ mb: 3, width: "60%", mt: 2 }}
        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
      />
      <Button
        variant="outlined"
        type="submit"
        size="large"
        sx={{
          backgroundColor: "#282583",
          color: "#fff",
          "&:hover": { backgroundColor: "#fff", color: "#282583" },
          mb: 3,
        }}
      >
        Log In
      </Button>
      <Link
        to={"//www.axleapi.com/terms-of-use"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="body2" align="center">
          Terms and Conditions
        </Typography>
      </Link>
    </Grid>
  );
}
