import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_NODE_ENV === "prod") {
  console.log = () => {};
}

if (
  process.env.REACT_APP_NODE_ENV === "prod" ||
  process.env.REACT_APP_NODE_ENV === "staging"
) {
  Sentry.init({
    dsn: "https://6e93758803084060afd41e9904d98472@o4504101533712384.ingest.sentry.io/4504854563520512",
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
