import { AxiosError, AxiosResponse } from "axios";

import axios from "../../axios";
import { AxiosRequestConfigExtended } from "../../axios";

export default class AuthService {
  static logIn(formData: { email: string; password: string }) {
    const config: AxiosRequestConfigExtended = {
      _bypassInterceptor: true,
    };
    return axios
      .post("/user/login", { ...formData }, config)
      .then((response) => {
        if (response.status === 201) {
          const token_expiration = Date.now();
          const user = { ...response.data, token_expiration };
          localStorage.setItem("user", JSON.stringify(user));
        }
        return response.data;
      })
      .catch((error) => {
        console.error("Login failed:", error);
        throw error;
      });
  }

  static logOut() {
    localStorage.removeItem("user");
  }

  static getCurrentUser() {
    return JSON.parse(localStorage.getItem("user")!);
  }

  static async refreshToken() {
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    return axios
      .post(
        "/refreshToken",
        { refresh_token: user.refresh_token },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        },
      )
      .then((response: AxiosResponse) => {
        if (response.status === 201) {
          const token_expiration = Date.now();
          const updatedUser = { ...user, ...response.data, token_expiration };
          localStorage.setItem("user", JSON.stringify(updatedUser));
          return response.data.access_token;
        }
        throw new Error("Failed to refresh token");
      })
      .catch((error: AxiosError) => {
        console.error("Token refresh failed:", error);
        throw error;
      });
  }
}
