import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";

import generateMagicLink from "../../helpers/GenerateMagicLink";
import { Fleet, initFleet } from "../../types/Fleet";
import { Maybe } from "../../types/Utility";
import editFleet from "../../helpers/EditFleet";

type FleetListProps = {
  fleets: Fleet[];
  admin: boolean;
  onDeleteFleet: (fleetId: number) => void;
  onEditFleet: () => void;
};

type PromiseArguments = {
  resolve: (value: Fleet | PromiseLike<Fleet>) => void;
  reject: (reason?: any) => void;
  newRow: Fleet;
  oldRow: Fleet;
};

const initPromiseArguments = {
  resolve: (_: Fleet | PromiseLike<Fleet>) => {},
  reject: (_?: any) => {},
  newRow: initFleet,
  oldRow: initFleet,
};

function computeMutation(newRow: Fleet, oldRow: Fleet) {
  if (newRow.FleetCode !== oldRow.FleetCode) {
    return `Fleet Code from '${oldRow.FleetCode}' to '${newRow.FleetCode}'`;
  }
  if (newRow.FleetName !== oldRow.FleetName) {
    return `Fleet Name from '${oldRow.FleetName || ""}' to '${newRow.FleetName || ""}'`;
  }
  return null;
}

export default function ManageFleetList({
  fleets,
  admin,
  onDeleteFleet,
  onEditFleet,
}: FleetListProps) {
  // Menu states
  const [anchorEl, setAnchorEl] = useState<Maybe<HTMLElement>>(null);
  const [selectedFleet, setSelectedFleet] = useState<Maybe<Fleet>>(null);

  // Add id field for fleets. Required for data grid filtering.
  const fleetsWithId = (fleets || []).map((fleet) => ({
    ...fleet,
    id: fleet.TSPID.toString() + "-" + fleet.ID.toString(),
  }));

  const handleClick = (event: React.MouseEvent<HTMLElement>, fleet: Fleet) => {
    setAnchorEl(event.currentTarget);
    setSelectedFleet(fleet);
  };

  const navigate = useNavigate();

  const navigateToMap = (fleet: Fleet) => {
    if (fleet) {
      navigate("/fleetMap/" + fleet.ID);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRelink = () => {
    if (selectedFleet) {
      generateMagicLink({
        fleetData: {
          fleetCode: selectedFleet.FleetCode,
        },
        expValue: 60 * 60, // one hour
      })
        .then((url) => {
          const newWindow = window.open(url, "_blank", "noopener,noreferrer");
          if (newWindow) newWindow.opener = null;
        })
        .catch((error) => {
          if (error instanceof Error) {
            setEditSnackbar({ children: error.message, severity: "error" });
          } else {
            try {
              setEditSnackbar({
                children: JSON.stringify(error),
                severity: "error",
              });
            } catch {
              setEditSnackbar({
                children: "An unknown error occurred.",
                severity: "error",
              });
            }
          }
        });
    }
    handleClose();
  };

  const handleDelete = () => {
    if (selectedFleet) {
      onDeleteFleet(selectedFleet.ID);
    }
    handleClose();
  };

  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [editFleetPromiseArguments, setEditFleetPromiseArguments] =
    React.useState<PromiseArguments>(initPromiseArguments);

  const [editSnackbar, setEditSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setEditSnackbar(null);

  const processRowUpdate = useCallback(
    (newRow: Fleet, oldRow: Fleet) =>
      new Promise<Fleet>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setEditFleetPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [],
  );

  const handleNo = () => {
    const { oldRow, resolve } = editFleetPromiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setEditFleetPromiseArguments(initPromiseArguments);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = editFleetPromiseArguments;
    try {
      await editFleet({
        fleetId: newRow.ID,
        fleetCode: newRow.FleetCode,
        fleetName: newRow.FleetName,
      });
      setEditSnackbar({
        children: "Fleet successfully updated",
        severity: "success",
      });
      setEditFleetPromiseArguments(initPromiseArguments);
      onEditFleet();
      resolve(newRow);
    } catch (error) {
      if (error instanceof Error) {
        setEditSnackbar({ children: error.message, severity: "error" });
      } else {
        try {
          setEditSnackbar({
            children: JSON.stringify(error),
            severity: "error",
          });
        } catch {
          setEditSnackbar({
            children: "An unknown error occurred.",
            severity: "error",
          });
        }
      }
      reject(oldRow);
      setEditFleetPromiseArguments(initPromiseArguments);
    }
  };

  const renderConfirmDialog = () => {
    if (!editFleetPromiseArguments.newRow.FleetCode) {
      return null;
    }

    const { newRow, oldRow } = editFleetPromiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: () => {} }}
        open={!!editFleetPromiseArguments.newRow}
      >
        <DialogTitle>Confirm change?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    { field: "ID", headerName: "Fleet ID", width: 100 },
    { field: "TSPName", headerName: "TSP", width: 150 },
    {
      field: "FleetCode",
      headerName: "Fleet Code",
      width: 200,
      editable: true,
    },
    {
      field: "FleetName",
      headerName: "Fleet Name",
      width: 150,
      editable: true,
    },
    { field: "NumberOfDrivers", headerName: "Drivers", width: 100 },
    { field: "NumberOfVehicles", headerName: "Vehicles", width: 100 },
    {
      field: "StaleCreds",
      headerName: "Status",
      width: 175,
      renderCell: (params: GridCellParams) => (
        <span style={{ color: params.value ? "red" : "green" }}>
          {params.value ? "Inactive" : "Active"}
        </span>
      ),
    },
    {
      field: "VerizonRevealStaleCreds",
      headerName: "",
      width: 50,
      renderCell: (params: GridCellParams) => {
        return params.value ? (
          <Tooltip
            placement="top"
            color="error"
            title={
              <>
                <p>Missing vehicle IDs for Verizon</p>
                <p>
                  Fleet must re-Link with their Verizon Reveal credentials so
                  their vehicles can be re-synced with the API.
                </p>
              </>
            }
          >
            <ErrorOutlineIcon />
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      align: "right",
      width: 100,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const fleet: Fleet = params.row;

        return (
          <>
            <IconButton
              style={{ marginRight: "6px" }}
              onClick={() => navigateToMap(fleet)}
            >
              <LocationOnIcon />
            </IconButton>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleClick(event, fleet)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={selectedFleet?.ID === fleet.ID && Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleRelink}>
                <Typography color="primary">Relink Fleet</Typography>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <Tooltip
                  title="WARNING: If your fleet is connected to multiple TSPs, 
                it will delete all TSPS associated with the fleet"
                  placement="left"
                >
                  <Typography color="error">Delete Fleet</Typography>
                </Tooltip>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  if (admin) {
    columns.splice(1, 0, {
      field: "ServiceName",
      headerName: "Service Name",
      width: 150,
    });
  }

  console.log(columns);

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      {renderConfirmDialog()}
      {fleetsWithId.length ? (
        <DataGrid
          rows={fleetsWithId}
          columns={columns}
          disableColumnFilter={true}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            height: "100%",
            width: "100%",
            "& .MuiDataGrid-root:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "ID", sort: "asc" }],
            },
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={() => {}}
        />
      ) : (
        <Typography variant="h6" align="center">
          You have no authenticated fleets
        </Typography>
      )}
      {!!editSnackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...editSnackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
