import { Route, Routes, useNavigate } from "react-router-dom";

import { Box, ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import "./App.css";
import AuthVerify from "./helpers/auth/AuthVerify";
import RequireAuth from "./helpers/auth/RequireAuth";
import "./index.css";
import LoginLayout from "./layouts/LoginLayout";
import Analytics from "./pages/Analytics";
import FleetMap from "./pages/FleetMap";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import SignUp from "./pages/SignUp";
import VehicleMap from "./pages/VehicleMap";

const theme = createTheme({
  typography: {
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
});

function App() {
  const navigate = useNavigate();

  const logOut = (): void => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AuthVerify logOut={logOut}>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Home admin={false} />
                </RequireAuth>
              }
            />
            <Route
              path="/fleetMap/:fleetId"
              element={
                <RequireAuth>
                  <FleetMap />
                </RequireAuth>
              }
            />
            <Route
              path="/vehicleMap/:vehicleId"
              element={
                <RequireAuth>
                  <VehicleMap />
                </RequireAuth>
              }
            />
            <Route
              path="/admin"
              element={
                <RequireAuth admin={true}>
                  <Home admin={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/analytics"
              element={
                <RequireAuth>
                  <Analytics />
                </RequireAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={
                <LoginLayout>
                  <Login />
                </LoginLayout>
              }
            />
            <Route
              path="/signup"
              element={
                <LoginLayout>
                  <SignUp />
                </LoginLayout>
              }
            />
          </Routes>
        </AuthVerify>
      </Box>
    </ThemeProvider>
  );
}

export default App;
