import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "../axios";
import VehicleMapView from "../components/fleets/VehicleMapView";
import getAuthHeader from "../helpers/auth/AuthHeader";
import AuthService from "../helpers/auth/AuthService";
import AuthVerify from "../helpers/auth/AuthVerify";
import RequireAuth from "../helpers/auth/RequireAuth";
import HomeLayout from "../layouts/HomeLayout";

export default function VehicleMap() {
  let { vehicleId } = useParams();

  const [locations, setVehicles] = useState([]);
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    const config = {
      headers: getAuthHeader(),
    };

    axios.get(`/vehicle/location_history/${vehicleId}`, config).then((res) => {
      if (res.status === 200) {
        setVehicles(res.data.vehicle_locations);
      }
    });
  }, [currentUser.access_token, vehicleId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RequireAuth>
      <HomeLayout>
        <AuthVerify logOut={AuthService.logOut}>
          <VehicleMapView locations={locations} />
        </AuthVerify>
      </HomeLayout>
    </RequireAuth>
  );
}
