import { useEffect } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";

import { Typography } from "@mui/material";

import { Location } from "../../types/Vehicle";

type MapViewProps = {
  locations: Location[];
};

type BoundsProps = {
  coords: [number, number][];
};

export default function VehicleMapView({ locations }: MapViewProps) {
  if (!locations || locations.length === 0) {
    return <Typography>No vehicle location data yet!</Typography>;
  }

  const vehicle_locations = locations.map((loc) => [
    loc.latitude,
    loc.longitude,
  ]);

  function Bounds({ coords }: BoundsProps) {
    const map = useMap();
    useEffect(() => {
      if (!map) return;

      map.fitBounds(coords);
    }, [map, coords]);
    return null;
  }

  const vehicle = locations[0];

  return (
    <MapContainer center={[42.36, -71.059]} zoom={12} scrollWheelZoom={false}>
      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <Marker
        position={[vehicle.latitude, vehicle.longitude]}
        eventHandlers={{
          click(e) {
            //   const location = e.target.getLatLng();
            //   map.flyToBounds([location]);
          },
        }}
        //   icon={icon}
      />

      {vehicle_locations.length > 0 ? (
        <Bounds coords={vehicle_locations as [number, number][]} />
      ) : null}

      <Polyline
        color={"red"}
        opacity={0.7}
        weight={20}
        positions={vehicle_locations as [number, number][]}
      >
        <Popup>Polygon</Popup>
      </Polyline>
    </MapContainer>
  );
}
