import React from "react";
import { Bar } from "react-chartjs-2";

import { Box, Grid, Typography } from "@mui/material";

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  Tooltip,
} from "chart.js";
import dayjs from "dayjs";

import { ChartData } from "../../types/ChartData";

Chart.register(CategoryScale, LinearScale, BarController, BarElement, Tooltip);

type AnalyticsBarProps = {
  data: ChartData;
  color: string;
  label: string;
  lastUpdated: Date;
  startAtZero?: boolean;
};

const AnalyticsBar: React.FC<AnalyticsBarProps> = ({
  data,
  color,
  label,
  lastUpdated,
  startAtZero = false,
}) => {
  const chartData = {
    labels: data.labels.map((label) => dayjs(label).format("MMM D")),
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 1.5,
    })),
  };

  const options: any = {
    scales: {
      y: {
        beginAtZero: startAtZero,
        display: true,
        precision: 0,
        grace: "25%",
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "nearest",
      },
    },
  };

  return (
    <Box bgcolor="white" borderRadius="borderRadius" p={3} boxShadow={1}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            <b>{label}</b>
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Bar data={chartData} options={options} />
      </Box>
      <Grid item xs={14}>
        <Typography
          variant="body2"
          align="right"
          style={{ fontSize: "12px" }}
          color={"gray"}
        >
          Last Updated: {dayjs(lastUpdated).format("MMM D, YYYY hh:mm A")}
        </Typography>
      </Grid>
    </Box>
  );
};

export default AnalyticsBar;
