import { Slider, Typography } from "@mui/material";

import * as dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

const marks = [
  600, 1200, 1800, 2400, 3000, 3600, 7200, 10800, 14400, 18000, 21600, 25200,
  28800, 32400, 36000, 39600, 43200, 46800, 50400, 54000, 57600, 61200, 64800,
  68400, 72000, 75600, 79200, 82800, 86400, 172800, 259200, 345600, 432000,
  518400, 604800, 691200, 777600, 864000, 950400, 1036800, 1123200, 1209600,
];

const formatExpValue = (value: number) => {
  return dayjs.duration(value, "seconds").humanize(true);
};

const getExpVal = (idx: number) => {
  return marks[idx];
};

type ExpirationSliderProps = {
  expValue: number;
  setExpValue: React.Dispatch<React.SetStateAction<number>>;
};

export default function ExpirationSlider({
  expValue,
  setExpValue,
}: ExpirationSliderProps) {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  return (
    <>
      <Typography id="non-linear-slider" gutterBottom>
        Expiration time: {formatExpValue(expValue)}
      </Typography>
      <Slider
        min={0}
        max={41}
        getAriaValueText={formatExpValue}
        valueLabelFormat={formatExpValue}
        onChange={(_, n) => setExpValue(getExpVal(n as number))}
        aria-label="expiration-slider"
        defaultValue={5}
        step={1}
        valueLabelDisplay="off"
      />
    </>
  );
}
