import { Box, Container } from "@mui/material";

export default function HomeFooter() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="//www.axleapi.com/terms-of-use"
          style={{ color: "red" }}
        >
          Terms and Conditions
        </a>
      </Container>
    </Box>
  );
}
