import { Navigate } from "react-router-dom";

import AuthService from "./AuthService";

type RequireAuthProps = {
  children: JSX.Element;
  admin?: boolean;
};

function RequireAuth({ children, admin }: RequireAuthProps) {
  const currentUser = AuthService.getCurrentUser();

  return currentUser ? (
    admin && !currentUser.admin ? (
      <Navigate to="/" replace />
    ) : (
      children
    )
  ) : (
    <Navigate to="/login" replace />
  );
}

export default RequireAuth;
