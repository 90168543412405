import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import { Box, Grid, Snackbar, Typography } from "@mui/material";

import * as Sentry from "@sentry/react";
import { AxiosRequestConfig } from "axios";

import axios from "../axios";
import AnalyticsBox from "../components/AnalyticsBox";
import AnalyticsBar from "../components/charts/AnalyticsBar";
import FleetLinkDialog from "../components/link/FleetLinkDialogue";
import generateMagicLink from "../helpers/GenerateMagicLink";
import getAuthHeader from "../helpers/auth/AuthHeader";
import AuthService from "../helpers/auth/AuthService";
import HomeLayout from "../layouts/HomeLayout";
import { ChartData } from "../types/ChartData";
import { Fleet } from "../types/Fleet";
import { Maybe } from "../types/Utility";
import ManageFleetList from "../components/fleets/ManageFleetList";
import deleteFleet from "../helpers/DeleteFleet";

type HomeProps = {
  admin: boolean;
};

export type MagicLinkFleetData = {
  fleetCode: string;
  isFleetCustomRedirect?: boolean;
  fleetRedirectURISuccess?: string;
  fleetRedirectURIError?: string;
};

type ServiceActivityData = {
  label: string;
  data: Array<number>;
};

type ServiceActivityResponse = {
  labels: Array<string>;
  datasets: Array<ServiceActivityData>;
  last_updated: Date;
};

export default function Home({ admin }: HomeProps) {
  const navigate = useNavigate();
  const auth = AuthService.getCurrentUser();
  const [magicLinkFleetData, setMagicLinkFleetData] =
    useState<MagicLinkFleetData>({
      fleetCode: "",
      isFleetCustomRedirect: false,
      fleetRedirectURISuccess: "",
      fleetRedirectURIError: "",
    });
  const [fleets, setFleets] = useState<Array<Fleet>>([]);
  const [errMessage, setErrMessage] = useState("");
  const [expValue, setExpValue] = useState(3600);
  const [serviceActivityData, setServiceActivityData] =
    useState<Maybe<ServiceActivityResponse>>(null);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

  Sentry.setUser({ email: auth.email, name: auth.name });

  const handleErrClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrMessage("");
  };

  const handleDeleteFleet = (fleetId: number) => {
    deleteFleet({ fleetId: fleetId })
      .then(() => {
        setFleets(fleets.filter((fleet) => fleet.ID !== fleetId));
      })
      .catch((errorMessage) => {
        console.error(errorMessage);
      });
  };

  const getFleets = useCallback(() => {
    const config: AxiosRequestConfig<Record<string, string>> = {
      headers: getAuthHeader(),
    };

    var endpoint = admin ? "/fleet/admindashboard" : "/fleet/alldashboard";

    axios.get(endpoint, config).then((res) => {
      if (res.status === 200) {
        setFleets(res.data);
      }
    });
  }, [admin]);

  // Chart data function and variables
  function getChartData(
    serviceActivityData: Maybe<ServiceActivityResponse>,
    label: string,
  ): Maybe<ChartData> {
    return serviceActivityData
      ? {
          labels: serviceActivityData.labels,
          datasets: serviceActivityData.datasets.filter(
            (dataset) => dataset.label === label,
          ),
        }
      : null;
  }

  const fleetData = getChartData(serviceActivityData, "ActiveFleets");
  const vehicleData = getChartData(serviceActivityData, "ActiveVehicles");
  const driverData = getChartData(serviceActivityData, "ActiveDrivers");

  const greetingMessage = () => {
    const currentHour = new Date().getHours();
    const currentUser = AuthService.getCurrentUser();
    const name = currentUser.first_name;

    if (currentHour < 12) {
      return `Good morning, ${name}!`;
    } else if (currentHour < 18) {
      return `Good afternoon, ${name}!`;
    } else {
      return `Good evening, ${name}!`;
    }
  };

  useEffect(() => {
    // get service activity data
    axios
      .get<ServiceActivityResponse>(`/service/activity/${auth?.service_id}`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        setServiceActivityData(response.data);
        setLastUpdated(response.data.last_updated);
      })
      .catch((error) => {
        console.error(error);
      });

    if (admin === true && auth.admin === false) {
      navigate("/", {
        replace: true,
      });
      window.location.reload();
      return;
    }

    getFleets();
  }, [
    auth?.access_token,
    auth?.admin,
    admin,
    navigate,
    auth?.service_id,
    getFleets,
  ]);

  const handleGenerateMagicLink = (redirect: boolean) => {
    generateMagicLink({ fleetData: magicLinkFleetData, expValue })
      .then((url) => {
        if (redirect) {
          window.location.href = `${url}&magicLink=true`;
        } else {
          navigator.clipboard.writeText(url);
          setErrMessage("Magic Link Copied");
        }
      })
      .catch((errorMessage) => {
        setErrMessage(errorMessage);
      });
  };

  return (
    <HomeLayout>
      <Grid container direction={"row"} spacing={2}>
        <Grid item xs={12}>
          <Box mb={2} mt={-2}>
            <Typography component="h4" variant="h4">
              {greetingMessage()}
            </Typography>
          </Box>
          <Typography component="p" variant="h6">
            {"Here is your snapshot for"}
            <span style={{ color: "#E50C45", fontSize: "22px" }}>
              <b>
                {"  Today, " +
                  new Date().toLocaleDateString(undefined, {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
              </b>
            </span>
            {":"}
          </Typography>
        </Grid>
        {fleets && fleets.length > 0 ? (
          <>
            <AnalyticsBox
              icon={<LocalShippingIcon fontSize="large" />}
              count={
                serviceActivityData?.datasets
                  .find((item) => item.label === "ActiveFleets")
                  ?.data.slice(-1)[0] || 0
              }
              label="Active Fleets"
            />
            <AnalyticsBox
              icon={<TimeToLeaveIcon fontSize="large" />}
              count={fleets[0].TotalVehicleCount}
              label="Total Vehicles"
            />
            <AnalyticsBox
              icon={<PersonIcon fontSize="large" />}
              count={fleets[0].TotalDriverCount}
              label="Total Drivers"
            />
          </>
        ) : null}
        <Grid item xs={4}>
          <Box p={0.5}>
            {fleetData && (
              <AnalyticsBar
                data={fleetData}
                lastUpdated={lastUpdated}
                color="#3C38B4"
                label="Active Fleets Over Time"
                startAtZero
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box p={0.5}>
            {vehicleData && (
              <AnalyticsBar
                data={vehicleData}
                lastUpdated={lastUpdated}
                color="#FE9659"
                label="Active Vehicles Over Time"
                startAtZero
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box p={0.5}>
            {driverData && (
              <AnalyticsBar
                data={driverData}
                lastUpdated={lastUpdated}
                color="#D90038"
                label="Active Drivers Over Time"
                startAtZero
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        spacing={2}
      >
        <Grid item xs={10}>
          <h2>Authenticated Fleets</h2>
        </Grid>
        {auth.demo === false ? (
          <Grid item xs={2}>
            <FleetLinkDialog
              magicLinkFleetData={magicLinkFleetData}
              setMagicLinkFleetData={setMagicLinkFleetData}
              expValue={expValue}
              setExpValue={setExpValue}
              generateMagicLink={handleGenerateMagicLink}
              serviceID={auth.service_id}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        <ManageFleetList
          fleets={fleets}
          admin={admin}
          onDeleteFleet={handleDeleteFleet}
          onEditFleet={getFleets}
        />
      </Grid>
      <Snackbar
        open={errMessage !== ""}
        autoHideDuration={6000}
        message={errMessage}
        onClose={handleErrClose}
      />
    </HomeLayout>
  );
}
