import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "../axios";
import FleetMapView from "../components/fleets/FleetMapView";
import getAuthHeader from "../helpers/auth/AuthHeader";
import AuthService from "../helpers/auth/AuthService";
import AuthVerify from "../helpers/auth/AuthVerify";
import HomeLayout from "../layouts/HomeLayout";

export default function FleetMap() {
  let { fleetId } = useParams();
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      axios
        .get(`/fleet/vehicles/locations/?fleet_id=${fleetId}`, {
          headers: getAuthHeader(),
        })
        .then((res) => {
          if (res.status === 200) {
            setVehicles(res.data.vehicle_locations);
          }
        });
    }
  }, [fleetId]);

  return (
    <AuthVerify logOut={AuthService.logOut}>
      <HomeLayout>
        <FleetMapView vehicles={vehicles} />
      </HomeLayout>
    </AuthVerify>
  );
}
