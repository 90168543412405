import { useEffect, useState } from "react";

import { Grid, Paper, Snackbar, TextField, Typography } from "@mui/material";

import * as Sentry from "@sentry/react";

import axios from "../axios";
import ButtonWithText from "../components/ButtonWithText";
import UserList from "../components/UserList";
import AuthService from "../helpers/auth/AuthService";
import HomeLayout from "../layouts/HomeLayout";
import { User } from "../types/User";

export default function Settings() {
  const [auth, setAuth] = useState(AuthService.getCurrentUser());
  const [callbackURI, setCallbackURI] = useState("");
  const [redirectURI, setRedirectURI] = useState("");
  const [users, setUsers] = useState<Array<User>>([]);
  const [snackMessage, setSnackMessage] = useState("");
  Sentry.setUser({ email: auth.email });

  const handleErrClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackMessage("");
  };

  useEffect(() => {
    setAuth(AuthService.getCurrentUser());

    const config = {
      headers: { Authorization: `Bearer ${auth.access_token}` },
    };

    axios.get("/service/" + auth.service_id, config).then((res) => {
      if (res.status === 200) {
        setCallbackURI(res.data.CallbackURI);
        setRedirectURI(res.data.RedirectURI);
      }
    });
  }, [auth.access_token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${auth.access_token}` },
    };

    axios.get("/service/users/", config).then((res) => {
      if (res.status === 200) {
        setUsers(res.data);
      }
    });
  }, [auth.access_token]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCallbackURI = () => {
    const config = {
      headers: { Authorization: `Bearer ${auth.access_token}` },
    };

    axios
      .put(
        "/service",
        {
          CallbackURI: callbackURI,
        },
        config,
      )
      .then((res) => {
        if (res.status === 200) {
          setSnackMessage("Callback URI Updated");
        } else {
          setSnackMessage("Error updating Callback URI");
        }
      })
      .catch((err) => {
        setSnackMessage(err.response.data);
      });
  };

  const updateRedirectURI = () => {
    const config = {
      headers: { Authorization: `Bearer ${auth.access_token}` },
    };

    axios
      .put(
        "/service",
        {
          RedirectURI: redirectURI,
        },
        config,
      )
      .then((res) => {
        if (res.status === 200) {
          setSnackMessage("Redirect URI Updated");
        } else {
          setSnackMessage("Error updating Redirect URI");
        }
      })
      .catch((err) => {
        setSnackMessage(err.response.data);
      });
  };

  const getUserSignupLink = () => {
    axios
      .post(
        "user/signup_token",
        {
          duration_seconds: 604800,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 201) {
          navigator.clipboard.writeText(res.data.url);
          setSnackMessage("User Signup Link Copied");
        }
      })
      .catch((err) => {
        if (err.response) {
          setSnackMessage(err.response.data);
        } else {
          setSnackMessage("Unknown error occurred.");
        }
      });
  };

  return (
    <HomeLayout>
      <Grid item xs={12}>
        <Typography component="h4" variant="h4">
          Integration Settings
        </Typography>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          mt: 2,
        }}
      >
        <Grid container>
          <Grid item container direction={"row"} spacing={2} mt={0}>
            <Grid item xs={10}>
              <TextField
                id="callback-uri"
                label="Callback URI"
                variant="outlined"
                value={callbackURI}
                onChange={(e) => setCallbackURI(e.target.value)}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={2} alignItems="stretch" style={{ display: "flex" }}>
              <ButtonWithText label="Update" handleClick={updateCallbackURI} />
            </Grid>
          </Grid>
          <Grid item container direction={"row"} spacing={2} mt={0}>
            <Grid item xs={10}>
              <TextField
                id="redirect-uri"
                label="Redirect URI"
                variant="outlined"
                value={redirectURI}
                onChange={(e) => setRedirectURI(e.target.value)}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={2} alignItems="stretch" style={{ display: "flex" }}>
              <ButtonWithText label="Update" handleClick={updateRedirectURI} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container alignItems="center" justifyContent="space-between" mt={3}>
        <Grid item>
          <h2> Users</h2>
        </Grid>
        <Grid item>
          <ButtonWithText
            label="Get User Signup Link"
            handleClick={getUserSignupLink}
          />
        </Grid>
      </Grid>
      <UserList users={users} />
      <Snackbar
        open={snackMessage !== ""}
        autoHideDuration={6000}
        message={snackMessage}
        onClose={handleErrClose}
      />
    </HomeLayout>
  );
}
